import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home/Home';
import ProjectPage from './components/pages/ProjectPage/ProjectPage';
import Contact from './components/pages/Contact/Contact';
import NoPage from './components/pages/NoPage/NoPage';
import { projects } from './data.js'
import './App.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="project" element={<Contact />} />
          {projects.map((d) => (
            <Route path={"project/" + d.id} element={<ProjectPage data={d}/>}/>
            ))}
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
