import React from 'react';
import Name from '../../Middle/Middle';
import './nopage.css';

function NoPage() {
    return (
        <div>
            <main>
                <Name />
                No Page
            </main>
        </div>
    );
}

export default NoPage;