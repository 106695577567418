import React from 'react';
import Name from '../../Middle/Middle';
import { projects, categories } from '../../../data.js'
import './contact.css';

function Contact() {
    return (
        <div>
            <main>
                <Name />
                +49123123123
                abladi [at] gmail [dot] com
                send me a postcard
            </main>
        </div>
    );
}

export default Contact;