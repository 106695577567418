import './middle.css';

export default function Middle() {
    return (
        <div className="name-container">
            <div className="letters">
                <div style={{paddingRight: "12px"}}>w</div>
                <div style={{paddingTop: "4px", paddingRight: "36px"}}>h</div>
                <div style={{paddingTop: "8px", paddingRight: "30px"}}>e</div>
                <div style={{paddingTop: "7px", paddingRight: "42px"}}>r</div>
                <div style={{paddingTop: "3px", paddingRight: "62px"}}>e</div>
                <div style={{paddingTop: "3px", paddingRight: "12px"}}>i</div>
                <div style={{paddingTop: "7px", paddingRight: "78px"}}>s</div>
                <div style={{paddingTop: "7px", paddingRight: "32px"}}>h</div>
                <div style={{paddingTop: "6px", paddingRight: "50px"}}>o</div>
                <div style={{paddingTop: "4px", paddingRight: "30px"}}>m</div>
                <div style={{paddingTop: "10px", paddingRight: "8px"}}>e</div>
            </div>
        </div>
    )
}