import './category.css';

export default function Category({data}) {
    return (
        <div 
            className="cat-container" 
            style={
                {left: data.x + "vw", top: data.y+"vh"}
                // {paddingLeft: data.x + "vw", paddingTop: data.y+"vh"}
            }
        >
            <div className="cat-area">
                <div className="cat-title">{data.title}</div>
            </div>
        </div>
    )
}