import React from 'react';
import { Link } from "react-router-dom";
import Name from '../../Middle/Middle';
import './projectpage.css';

function ProjectPage({data}) {

    const categories = data.cat
    const paragraphs = data.desc.split("\n")
    const images = data.img

    return (
        <div>
            <Link className="link" to={"/"}>
                <Name id="homeLink"/>
            </Link>
            <div className="project-page-container">
                <header>
                    <h1>{data.title}</h1>
                    <h2>{data.location}</h2>
                    <h2>{data.year}</h2>
                    {categories.map((d) => (
                        <h3 className="categories">{d}</h3>
                    ))}
                </header>
                <main>
                    {paragraphs.map((d) => (
                        <p>{d}<br/></p>
                    ))}
                    <br/>
                    {images.map((d) => (
                        <img src={"/images/"+d}></img>
                    ))}
                </main>
            </div>
        </div>
    );
}

export default ProjectPage;