export const projects = [
    {
        id: 1,
        title: 'i used to fall every autumn',
        location: 'budapest',
        cat: ['installations', 'sound/music'],
        year: '2019',
        x: 68,
        y: 28,
        desc: "> Tactile interactive installation\
        \nAudio samples, tablet, playtron device, crocodile \
        cables, leaves, branches and knife\
        \n\nAs part of 2019 BARTR Residency Exhibition \
        in Patyolat, Budapest (5.12—11.12)\
        \n\nThis installation delves into the anxieties and fears surrounding \
        the ongoing climate crisis. The interactive apparatus created is a reflection \
        of the bleak future that we may face if we do not take action to protect the \
        environment.\
        \n\nThe installation showcases a collection of samples from the dying nature, \
        lending voice to the fallen leaves and branches, and ultimately creating a haunting \
        atmosphere.\
        \n\nWith the use of a MIDI controller developed by Playtronica, the spectator can \
        participate in the installation by triggering cynical and cold spoken words, such as \
        \"Don't be sorry, be careful\" or \"Breathe in, breathe out, until you're out, and suffocate, \
        slowly.\" This interactive element adds an extra layer of emotional intensity and personal \
        responsibility to the installation.\
        \n\nTo activate the samples, the spectator must hold a kitchen knife in one hand and a \
        hanging branch in the other. This gesture symbolizes the duality of destruction and preservation \
        that we all face in our daily lives. Through this interactive element, the installation aims \
        to inspire a sense of agency and personal responsibility in the viewer.\
        \n\nPitcures by Sara Herrlander\
        \n\n> Video :\nhttps://vimeo.com/423984479",
        img: ['i_used_to_06.jpg', 'i_used_to_03.jpg', 'i_used_to_04.jpg', 'i_used_to_05.jpg', 'i_used_to_02.jpg']
    },
    {
        id: 2,
        title: 'birds chirping in the background',
        location: 'budapest',
        cat: ['installations', 'sound/music'],
        year: '2019',
        x: 62,
        y: 14,
        desc: "> Immersive installation\
        \nAudio loop 2'02, tablet, mixer, 12 reversed \
        amplified piezo microphones, 22 bike lights and \
        beach chair\
        \n\nThis installation consists of a dark booth where the spectator is placed, \
        isolated from the outside world. Once inside, one hears distorted bird songs that \
        are played through low-quality speakers, creating an eerie soundscape. These speakers \
        are made from reversed piezo microphones, giving the sound a distorted and unnerving \
        quality.\
        \n\nTo add to the disorienting experience, stroboscopic bike lights are used to blind \
        the viewer, making them lose their sense of time and space. This creates a unique and \
        challenging sensory experience, where the sound and light play off each other to create \
        a sense of unease and discomfort.\
        \n\nThe title is a cynical nod to the way we often perceive nature as a passive and unimportant \
        backdrop to our lives. By distorting and manipulating the sounds of birds, the installation \
        questions our relationship to the natural world and forces us to confront the way we often \
        take it for granted.\
        \n\n> Video :\nhttps://vimeo.com/424010991",
        img: ['birds_chirping_01.jpg', 'birds_chirping_03.jpg', 'birds_chirping_02.jpg', 'birds_chirping_06.jpg']
    },
    {
        id: 3,
        title: 'lahmacun radio',
        location: 'online',
        cat: ['programming'],
        year: '2021~',
        x: 5,
        y: 44,
        desc: "Lahmacun Radio's repository\
        \n\nOpen-source contributor as a data engineer \
        to extract, sanitize, compute and output listeners data\
        and other metrics from AzuraCast API in order to monitor\
        traffic on both the website and the app.\
        \n\nRepo : https://github.com/lahmacunradio/analytics",
        img: []
    },
    {
        id: 4,
        title: 'transverszia podcast',
        location: 'online',
        cat: ['music/sound'],
        year: '2021~',
        x: 78,
        y: 55,
        desc: "> Monthly podcast on Lahmacun Radio\
        \n\nA transversal sonic journey between genres, arts and practices, \
        that explores experimental, DIY and innovative approach to music-making \
        \n\nOne of two episodes features guestmixes from artists encountered during \
        residencies all over Europe. These guests bring their unique perspectives and \
        sensitivity that transcend borders and boundaries, broadening horizons.\
        \n\nOn air every Monday 11:11–12:11",
        img: ['transverszia_01.jpg', 'transverszia_26.jpg', 'transverszia_22.jpg', 'transverszia_23.jpg']
    },
    {
        id: 5,
        title: 'music releases',
        location: 'berlin, budapest, paris',
        cat: ['music/sound', 'performances'],
        year: '2019~',
        x: 75,
        y: 75,
        desc: "2019\nTerenor Records | Propagation 2019 Compilation\
        \nhttps://radiationmutation.bandcamp.com/track/jun-suzuki-antjemina\
        \n\n2020\nExiles Electronics | Exiles VA\
        \nhttps://exiles-electronics.bandcamp.com/track/jun-suzuki-cso-384-400\
        \n\n2020\nLahmacun Radio | KKH Support Compilation\
        \nhttps://lahmacunradio.bandcamp.com/track/jun-suzuki-full-hd-full-dream\
        \n\n2021\nMJUT | Surroundings 2 Compilation\
        \nhttps://mjutclub.bandcamp.com/track/jun-suzuki-apneaphilia\
        \n\n",
        img: ['bandcamp_03.jpg', 'bandcamp_01.jpg', 'bandcamp_04.jpg', 'bandcamp_02.jpg']
    },
    {
        id: 6,
        title: 'интерference',
        location: 'georgia',
        cat: ['programming', 'installations', 'sound/music'],
        year: '2022',
        x: 45,
        y: 30,
        desc: "> Generative sound installation\
        \nOmnidirectional geophone, contact microphones, metal rods, \
        modular synthesizer, laptop, mixer, two sets of speakers.\
        \n\nинтерference is a first time collaboration between \
        Anastasia Zhylinskaya and Jun Suzuki that brings together \
        their combined interests in generative and installation art.\
        Resulting from a 2 weeks residency in Tusheti, Georgia \
        (about 10 km from the Russian frontier), the artists explore \
        their interpretation of borders and what evade them : natural \
        elements and electromagnetic waves.\
        \n\nUsing contact microphones loosely attached to metal rods \
        subject to outdoors elements, and an omnidirectional geophone \
        that captures the faintest vibrations on the floor, wind, rain \
        and footsteps become triggers of enveloping soundscapes.\
        The sounds generated by the natural elements outside are \
        amplified and processed by modular synthesis and submerge the \
        room into an endless sombre drone punctuated by crashing roars \
        of metal rods.\
        Simultaneously, each footstep randomly triggers a new \
        short-term frequency from an SDR (Software Defined Radio) \
        playing broadcasts from all over the world, thus switching \
        from music, news and talks to machine bleeps and static noise.\
        \n\nIn times of political conflicts and compromised boundaries, \
        the artists question the inevitability of history, and how \
        sound and unscripted noise cut across these borders to transmit \
        an alternative sense of togetherness.",
        img: ['интерference_01.jpg', 'интерference_02.jpg', 'интерference_03.jpg', 'интерference_04.jpg']
    },
    {
        id: 7,
        title: 'fluids',
        location: 'berlin',
        cat: ['performance', 'sound/music'],
        year: '2022',
        x: 60,
        y: 70,
        desc: "> Live performance\
        \nMovement and sound improvisation collaboration\
        \n\nFluids is an intimate duet in collaboration with \
        Jun Suzuki and Alvin Collantes exploring emotional upheavals \
        through a close interaction of body movements and sound \
        improvisation. \
        \n\nInspired by their parallel childhood upbringings \
        in Japan & The Philippines, Jun and Alvin share the stories \
        that were left behind, emotional landscapes of grief and the \
        struggles in identities, belongingness & acceptance. \
        \n\nTogether, they experiment beyond their respective boundaries \
        to explore and stimulate the correlation between one another's \
        sensibilities and stage an intertwined dialogue between \
        unspoken states of body, mind and soul. \
        \n\nMirroring emotions as fluids, continuous, variable and \
        constantly shape-shifting.\
        \n\n> Video (teaser) :\nhttps://vimeo.com/776761734\
        \n\n> Video (full show) :\nhttps://vimeo.com/774674061\
        \n\n> Portfolio :\nhttps://drive.google.com/file/d/17aED59UoyR8hhMyuM7JDCHqkhK9Vwuc0/view?usp=share_link",
        img: ['fluids_apal_1.png', 'fluids_apal_3.png', 'fluids_apal_4.png', 'fluids_savvy_2.png', 'fluids_savvy_3.png']
    },
    {
        id: 8,
        title: 'wild access',
        location: 'berlin',
        cat: ['performance', 'sound/music'],
        year: '2022',
        x: 66,
        y: 65,
        desc: "> Site-specific performance series \
        \n\nWild Access Lichtenberg is an innovative outdoor performance that combines elements \
        of an audio walk and theater performance, incorporating lights, sound, and dance. Each \
        participant wears wireless headphones and is guided through the experience by two \
        facilitators who communicate through talkie-walkies. The installation provides an \
        immersive journey that stimulates multiple senses and encourages the viewer to engage \
        with the outdoor surroundings on a deeper level.\
        ",
        img: ['wild_access_01.jpg', 'wild_access_02.jpg']

    },
    {
        id: 9,
        title: 'portfolio website',
        location: 'marseille',
        cat: ['programming'],
        year: '2022',
        x: 10,
        y: 26,
        desc: "Hey lovelies,\
        \n\nthis is a static website made with React and JSX.\
        Nothing fancy for the moment, but planning on\
        adding D3 elements to *connect the dots* at some point...\
        \n\n:))",
        img: ['pp-small-anon.jpg']
    },
];

export const categories = [
    {
        id: 1,
        title: 'installations',
        x: 45,
        y: 18
    },
    {
        id: 2, 
        title: 'programming',
        x: 15,
        y: 40
    },
    {
        id: 3,
        title: 'sound/music',
        x: 70,
        y: 55
    },
    {
        id: 4,
        title: 'performance',
        x: 50,
        y: 70
    },
];