import './project.css';
import * as d3 from 'd3';
import {useState, useRef } from 'react';
import { Link } from "react-router-dom";


export default function Project({data}) {
    // const svgRef = useRef();
    // const [w, setW] = useState(window.innerWidth);
    // const [h, setH] = useState(window.innerHeight);
    // const xOffset = 36
    // const yOffset = 65

    // const svg = d3.select(svgRef.current)
    // .attr('width', w)
    // .attr('height', h)
    // .style('position', 'absolute')
    // .style('top', "-" + (data.y) + "vh")
    // .style('left', "-" + data.x + "vw")
    // .style('border', '1px solid red')

    // svg.append('line')
    // .style('stroke', '#ddd')
    // .style('stroke-width', 1)
    // .style('opacity', 0.1)
    // .attr('id', "line" + data.id)
    // .attr('x1', w * data.x/100 + xOffset)
    // .attr('y1', h * data.y/100 + yOffset)
    // .attr('x2', 500)
    // .attr('y2', 500)
    

    return (
        <div>
            <div 
                className={data.id + " project-container"} 
                style={
                    {left: data.x + "vw", top: data.y+"vh"}
                }
                >
                <Link className={data.id + " project-area"} to={"project/" + data.id}>
                    <div id="star" className={data.id + " project"}>*</div>
                    <div className={data.id + " project-title"}>{data.title}, {data.location}, {data.year}</div>
                </Link>
                {/* <svg ref={svgRef}></svg> */}
            </div>
        </div>
    )
}